import styles from './TwoUpDocCard.module.scss';
// import Byline, { Author } from './partials/ByLineList/Byline';
import { baseImageConfig, getImageUrl } from 'lib/cloudinary';
import React from 'react';
import BylineList, {
  Author,
} from '../ScrollCarousel/partials/ByLineList/Byline';

// make sure we have a center crop on mobile in imgConfig

const imgConfig = {
  mobile: {
    ...baseImageConfig,
    height: 282,
    width: 275,
  },
  mobileHalves: {
    ...baseImageConfig,
    height: 282,
    width: 137.5,
  },
  tablet: {
    ...baseImageConfig,
  },
  tabletHalves: {
    ...baseImageConfig,
  },
  desktop: {
    ...baseImageConfig,
    height: 282,
    width: 560,
  },
  desktopHalves: {
    ...baseImageConfig,
    height: 282,
    width: 280,
  },
};

export interface CardImage {
  cloudinaryId: string;
  altText?: string;
}

export interface TwoUpDocCardProps {
  href: string;
  images: CardImage | CardImage[];
  headline: string;
  description: string;
  authors: Author[];
}

const TwoUpDocCard: React.FC<TwoUpDocCardProps> = ({
  href,
  images,
  headline,
  description,
  authors,
}) => {
  const arrayOfImages = Array.isArray(images);
  return (
    <a
      className={styles.twoUpCardWrapper}
      id={arrayOfImages ? 'image-array' : undefined}
      href={href}
    >
      {arrayOfImages ? (
        <div className={styles.twoUpCardImagesContainer}>
          {images.map((el) => {
            const { altText, cloudinaryId } = el;
            return (
              <picture
                className={styles.multiImage}
                key={`${el?.cloudinaryId}-image`}
              >
                <source
                  srcSet={getImageUrl(cloudinaryId, imgConfig.desktopHalves)}
                  media="(min-width: 1024px)"
                />
                <source
                  srcSet={getImageUrl(cloudinaryId, imgConfig.tabletHalves)}
                  media="(min-width: 768px)"
                />
                <img
                  src={getImageUrl(cloudinaryId, imgConfig.mobileHalves)}
                  alt={altText ? altText : headline}
                />
              </picture>
            );
          })}
        </div>
      ) : (
        <picture className={styles.twoUpCardImage}>
          <source
            srcSet={getImageUrl(images.cloudinaryId, imgConfig.desktop)}
            media="(min-width: 1024px)"
          />
          <source
            srcSet={getImageUrl(images.cloudinaryId, imgConfig.tablet)}
            media="(min-width: 768px)"
          />
          <img
            src={getImageUrl(images.cloudinaryId, imgConfig.mobile)}
            alt={images.altText}
            loading="lazy"
          />
        </picture>
      )}
      <div className={styles.twoUpCardContent}>
        <div>
          <h3 className={styles.twoUpCardHeadline}>{headline}</h3>
          <span className={styles.twoUpCardDescription}>{description}</span>
        </div>
        <div className={styles.footer}>
          <BylineList authors={authors} />
        </div>
      </div>
    </a>
  );
};

export default TwoUpDocCard;
