import styles from './ToggleProcessGrid.module.scss';
import { useState, useRef } from 'react';
import { getImageUrl, baseImageConfig } from '../../../lib/cloudinary';

interface UpsellCardProps {
  header: string;
  content: string;
  cloudinaryId: string;
}
interface ProcessGrid {
  title: string;
  cardDeck: UpsellCardProps[];
}

interface UpsellCardContainerProps {
  processGrid: ProcessGrid[];
}

const ToggleProcessGrid: React.FC<UpsellCardContainerProps> = ({
  processGrid,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const hasToggle = processGrid && processGrid.length > 1;
  const [currentIndex, setCurrentIndex] = useState(0);
  const toggleCardDeck = (index: number) => {
    setCurrentIndex(index);
    ref.current?.scroll(0, 0);
  };
  const cardDeck = processGrid[currentIndex].cardDeck;

  return (
    <div data-testid={`process-grid-toggle-${hasToggle}`}>
      {hasToggle && (
        <div className={styles.toggleWrapper}>
          {processGrid.map((grid, index) => {
            const mixpanelData = {
              link_name: grid?.title,
            };

            return (
              <button
                className={`${styles.toggleButton} ${
                  index === currentIndex ? styles.active : ''
                }`}
                key={index}
                onClick={() => toggleCardDeck(index)}
                data-mx={JSON.stringify(mixpanelData)}
              >
                <p>{grid.title}</p>
              </button>
            );
          })}
        </div>
      )}
      <div className={styles.processCardContainer} ref={ref}>
        {cardDeck.map((card, index) => (
          <div key={index} className={styles.cardWrapper}>
            <div className={styles.imageWrapper}>
              <img
                className={styles.image}
                src={getImageUrl(card.cloudinaryId, {
                  ...baseImageConfig,
                  width: 275,
                  height: 458,
                })}
                alt={card.header}
                loading="lazy"
              ></img>
            </div>
            <div className={styles.contentWrapper}>
              <h3 className={styles.headline}>{card.header}</h3>
              <p
                className={styles.copy}
                dangerouslySetInnerHTML={{ __html: card.content }}
              ></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToggleProcessGrid;
