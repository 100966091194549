import React from 'react';
import SiteHeader from 'components/modules/SiteHeader';
import useAppContext from 'lib/hooks/useAppContext';
import styles from './BrandedLandingPageLayout.module.scss';

type AccountLayoutProps = {
  canonical: string;
  meta: Record<string, any>;
  children?: React.ReactNode;
};

const BrandedLandingPageLayout = ({
  canonical,
  meta,
  children,
}: AccountLayoutProps) => {
  const { domainSiteKey, pathname, queryParams, url } = useAppContext();
  return (
    <main className={styles.mainPageWrapper}>
      <SiteHeader
        canonical={canonical}
        meta={meta}
        pathname={pathname}
        queryParams={queryParams}
        reducedTracking={false}
        siteKey={domainSiteKey}
        url={url}
      />
      <div className={styles.bodyContentWrapper}>{children}</div>
    </main>
  );
};

export default BrandedLandingPageLayout;
