import { MouseEvent } from 'react';
import analytics from 'lib/analytics';
import { analyticsLog } from 'lib/hooks/mixpanel';

const linkVerticalDepth = (clickedElement: HTMLElement) => {
  const rect = clickedElement.getBoundingClientRect();
  const positionFromTop = rect.top + window.scrollY;

  return Math.round(positionFromTop);
};

export const linkSelectionHelper = () => {
  return (event: MouseEvent<HTMLElement>) => {
    const clickedElement = event.target as HTMLElement;
    const data = clickedElement.closest<HTMLElement>('[data-mx]')?.dataset.mx;

    if (!data) return;

    const positionFromTop = linkVerticalDepth(clickedElement);

    try {
      const dataJson = JSON.parse(data);
      analytics.track(
        'BRAND_PAGE_ITEM_CLICK',
        {
          ...dataJson,
          link_vertical_depth: `${positionFromTop}px`,
        },
        { transport: 'sendBeacon' },
      );
    } catch {
      analyticsLog(`error parsing ${data}`);
    }
  };
};
