import styles from './TwoColumnText.module.scss';
import cx from 'classnames';

interface CtaLinks {
  className?: string;
  linkHref: string;
  linkText: string;
  target?: string;
}

export interface TwoColumnTextProps {
  description: string;
  headline: string;
  ctaLinks?: CtaLinks[];
}

const TwoColumnText: React.FC<TwoColumnTextProps> = ({
  description,
  headline,
  ctaLinks,
}) => {
  const cellClassNames = cx(styles.twoColumnTextContentWrapper, {
    [styles.link]: ctaLinks,
  });
  const testIdVariable = ctaLinks ? 'with-link' : 'no-link';
  return (
    <div
      data-testid={`two-column-text-${testIdVariable}`}
      className={cellClassNames}
    >
      <h3
        className={`${styles.twoColumnTextHeadline} ${styles.image1}`}
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <span
        className={`${styles.twoColumnTextDescription} ${styles.image2}`}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {ctaLinks && (
        <div className={styles.image3}>
          {ctaLinks.map((ctaLink, index) => {
            const { className, linkText, linkHref, target } = ctaLink;
            const mixpanelData = {
              link_name: linkText,
              destination_URL: linkHref,
            };
            const linkClasses = cx(styles.twoColumnTextLink, {
              [styles[`${className}`]]: className,
            });
            return (
              <a
                key={index}
                className={linkClasses}
                data-mx={JSON.stringify(mixpanelData)}
                href={linkHref}
                target={target ? target : '_self'}
              >
                {linkText}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TwoColumnText;
