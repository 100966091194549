import honeybadger from 'lib/errors/honeybadger';
import { Cookies } from 'react-cookie';

export interface PostPianoConversionProps {
  trackingId?: string;
  termId?: string;
  termName: string;
}

export const postPianoConversion = async ({
  trackingId,
  termId,
  termName,
}: PostPianoConversionProps) => {
  const pianoConversionParams = {
    tracking_id: trackingId,
    term_id: termId,
    term_name: termName,
  };

  try {
    window.tp.api.callApi(
      '/conversion/log',
      pianoConversionParams,
      (response: { code: number }) => {
        if (response.code === 0) {
          const cookies = new Cookies();
          cookies.remove('piano_tracking_id');
        }
      },
    );
  } catch (error: any) {
    honeybadger?.notify(error);
  }
};
