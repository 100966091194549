import { useState } from 'react';
import styles from './Testimonials.module.scss';

interface TestimonialProps {
  quotes: {
    id: number;
    quote: string;
    citation: string;
  }[];
}

const Testimonials: React.FC<TestimonialProps> = ({ quotes }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const setTestimonial = (index: number) => {
    setCurrentIndex(index);
  };
  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + quotes.length) % quotes.length,
    );
  };
  return (
    <div className={styles.testimonials} data-testid="testimonial-carousel">
      <div key={quotes[currentIndex].id} className={styles.testimonialContent}>
        <p className={styles.testimonial}>“{quotes[currentIndex].quote}”</p>
        <p
          className={styles.citation}
          dangerouslySetInnerHTML={{
            __html: quotes[currentIndex].citation,
          }}
        />
      </div>
      <div className={styles.activeItems}>
        <div className={styles['focus-active-items']}>
          {quotes.map((quote, index) => (
            <button
              key={quote.id}
              aria-label={`Display testimonial ${quote.id + 1}`}
              onClick={() => setTestimonial(index)}
              className={index === currentIndex ? styles.active : ''}
            ></button>
          ))}
        </div>
      </div>
      <div className={styles['directional-active-items']}>
        <button aria-label="Previous testimonial" onClick={prevTestimonial}>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="15" stroke="currentColor" />
            <path
              d="M18.4062 10L12.4062 16L18.4062 22"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button aria-label="Next testimonial" onClick={nextTestimonial}>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="15" stroke="currentColor" />
            <path
              d="M13.4062 22L19.4063 16L13.4063 10"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
