import { useState } from 'react';
import styles from './FullWidthImageCarousel.module.scss';
import { baseImageConfig, getImageUrl } from 'lib/cloudinary';

interface FullWidthImageCarouselProps {
  images: {
    cloudinaryId: string;
    altText: string;
  }[];
}

const FullWidthImageCarousel: React.FC<FullWidthImageCarouselProps> = ({
  images,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const setImage = (index: number) => {
    setCurrentIndex(index);
  };
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const { cloudinaryId, altText } = images[currentIndex];
  const imgConfig = {
    mobile: {
      ...baseImageConfig,
      aspectRatio: '16:9',
      height: 243,
    },
    tablet: {
      ...baseImageConfig,
      aspectRatio: '16:9',
      height: 432,
    },
    desktop: {
      ...baseImageConfig,
      aspectRatio: '16:9',
      height: 639,
    },
  };

  return (
    <div
      data-testid="full-width-image-carousel"
      className={styles.fullWidthImageCarouselWrapper}
    >
      <picture className={styles.fullWidthImageWrapper}>
        <source
          srcSet={getImageUrl(cloudinaryId, imgConfig.desktop)}
          media="(min-width: 1024px)"
        />
        <source
          srcSet={getImageUrl(cloudinaryId, imgConfig.tablet)}
          media="(min-width: 768px)"
        />
        <img
          loading="lazy"
          src={getImageUrl(cloudinaryId, imgConfig.mobile)}
          alt={altText}
        />
      </picture>
      {images.length > 1 && (
        <>
          <div className={styles['focus-active-items']}>
            {images.map((el, i) => {
              return (
                <button
                  key={`${el?.cloudinaryId}-${i}`}
                  aria-label={`Display image ${el?.altText}`}
                  onClick={() => setImage(i)}
                  className={i === currentIndex ? styles.active : ''}
                ></button>
              );
            })}
          </div>
          <div className={styles['directional-active-items']}>
            <button aria-label="Previous testimonial" onClick={prevImage}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <circle cx="16" cy="16" r="15" stroke="currentColor" />
                <path
                  d="M18.4062 10L12.4062 16L18.4062 22"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button aria-label="Next testimonial" onClick={nextImage}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <circle cx="16" cy="16" r="15" stroke="currentColor" />
                <path
                  d="M13.4062 22L19.4063 16L13.4063 10"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FullWidthImageCarousel;
