import styles from './TwoUpDocCardCarousel.module.scss';
import TwoUpDocCard, { TwoUpDocCardProps } from '../TwoUpDocCard/TwoUpDocCard';
import ScrollCarousel from '../ScrollCarousel/ScrollCarousel';

interface TwoUpDocCardCarouselProps {
  carouselCards: TwoUpDocCardProps[];
  carouselTitle: string;
}

const TwoUpDocCardCarousel: React.FC<TwoUpDocCardCarouselProps> = ({
  carouselCards,
  carouselTitle,
}) => (
  <div
    className={styles.twoUpDocCardCarouselWrapper}
    data-testid={`two-up-card-carousel-${carouselTitle}`}
  >
    <ScrollCarousel id="twoUpDocCardCarouselWrapper" title={carouselTitle}>
      {carouselCards.map((el) => {
        const { authors, description, headline, href, images } = el;
        return (
          <TwoUpDocCard
            key={`${headline}-two-up-card`}
            href={href}
            images={images}
            headline={headline}
            description={description}
            authors={authors}
          />
        );
      })}
    </ScrollCarousel>
  </div>
);

export default TwoUpDocCardCarousel;
