import { PianoEvent } from '../../types';
import analytics from 'lib/analytics';

import { postPianoConversion } from '../../helpers/postPianoConversion';
import { advancedBlueconicEvent } from 'lib/utils/blueconicEvents';
import { setTrackingCookies } from '../../helpers/setPianoTrackingCookies';

interface BrandLandingFreeTrialEventProps extends FreeTrialAnalyticsProps {
  event: PianoEvent;
}

interface FreeTrialAnalyticsProps {
  ad_type?: string;
  incode?: string;
  location: string;
  offers_presented?: string;
  offer_selected?: string;
}

const redirectToHref = (href: string) => {
  return (document.location.href = href);
};

const fireFreeTrialAnalytics = ({
  ad_type,
  incode,
  location,
  offers_presented,
  offer_selected,
}: FreeTrialAnalyticsProps) => {
  advancedBlueconicEvent({
    eventName: 'Brand Page Ad',
    context: [`${offer_selected}`],
  });
  analytics.track(
    'BRAND_PAGE_AD',
    {
      location,
      status: 'Accepted',
      ad_type,
      offers_presented,
      offer_selected,
      incode,
    },
    { transport: 'sendBeacon' },
  );
};

export const brandLandingFreeTrialEvent = ({
  location,
  event,
}: BrandLandingFreeTrialEventProps) => {
  const {
    eventName,
    params: {
      adType: ad_type,
      offersPresented: offers_presented,
      offerSelected: offer_selected,
      incode,
      hrefDestination: href,
      trackingid: trackingId,
    },
  } = event;

  setTrackingCookies(trackingId);

  switch (eventName) {
    case 'trial-or-gift-signup':
      fireFreeTrialAnalytics({
        ad_type,
        location,
        incode,
        offers_presented,
        offer_selected,
      });

      if (offer_selected !== 'digital_trial') {
        postPianoConversion({
          trackingId,
          termId: 'Gift Subscription',
          termName: 'Gift Subscription',
        });
      }

      if (href) redirectToHref(href);
      break;
    case 'magazine-subscription-redirect':
      fireFreeTrialAnalytics({
        ad_type,
        location,
        incode,
        offers_presented,
        offer_selected,
      });

      postPianoConversion({
        trackingId,
        termId: 'Magazine Subscription',
        termName: 'Magazine Subscription',
      });

      if (href) redirectToHref(href);
      break;
    case 'atk-shop-redirect':
      fireFreeTrialAnalytics({
        ad_type,
        location,
        incode,
        offers_presented,
        offer_selected,
      });

      postPianoConversion({
        trackingId,
        termId: 'ATK Shop Redirect',
        termName: 'ATK Shop Redirect',
      });

      if (href) redirectToHref(href);
      break;
  }
};
