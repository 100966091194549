import styles from './TeamMemberCard.module.scss';
import { getImageUrl, baseImageConfig } from 'lib/cloudinary';

export interface TeamMemberCardProps {
  cloudinaryId: string;
  name: string;
  description: string;
  path: string;
}

const memberImageProps = {
  ...baseImageConfig,
  height: 100,
  width: 100,
};

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  cloudinaryId,
  name,
  description,
  path,
}) => {
  return (
    <a href={path}>
      <div className={styles.teamMemberCardWrapper}>
        <div className={styles.teamMemberCardImage}>
          <img
            alt={`${name} headshot`}
            src={getImageUrl(cloudinaryId, memberImageProps)}
            loading="lazy"
          />
        </div>
        <h3 className={styles.teamMemberCardName}>{name}</h3>
        <span
          className={styles.teamMemberCardDek}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </a>
  );
};

export default TeamMemberCard;
