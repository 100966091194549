import styles from './TeamMemberCarousel.module.scss';
import TeamMemberCard, {
  TeamMemberCardProps,
} from '../TeamMemberCard/TeamMemberCard';
import ScrollCarousel from 'components/brandpages/ScrollCarousel/ScrollCarousel';

interface TeamMemberCarouselProps {
  carouselCards: {
    authorCards: TeamMemberCardProps[];
    title: string;
  };
}

const TeamMemberCarousel: React.FC<TeamMemberCarouselProps> = ({
  carouselCards,
}) => {
  const { title, authorCards } = carouselCards;
  return (
    <div
      className={styles.teamMemberCarouselWrapper}
      data-testid="team-member-card-carousel"
    >
      <ScrollCarousel
        id="teamMemberCarouselWrapper"
        title={title}
        className="centered"
      >
        {authorCards.map((el) => {
          const { cloudinaryId, description, name, path } = el;
          return (
            <TeamMemberCard
              key={`team-carousel-${name}}`}
              cloudinaryId={cloudinaryId}
              description={description}
              name={name}
              path={path}
            />
          );
        })}
      </ScrollCarousel>
    </div>
  );
};

export default TeamMemberCarousel;
