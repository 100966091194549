import styles from './fullWidthHeadline.module.scss';

interface FullWidthHeadlineProps {
  header: string;
}

const FullWidthHeadline: React.FC<FullWidthHeadlineProps> = ({ header }) => {
  return (
    <div data-testid="full-width-headline" className={styles.wrapper}>
      <h2 className={styles.header}>{header}</h2>
    </div>
  );
};

export default FullWidthHeadline;
