import analytics from 'lib/analytics';
import { postPianoConversion } from '../../helpers/postPianoConversion';
import { newsletterDataMap } from './AdsPresented';
import { advancedBlueconicEvent } from 'lib/utils/blueconicEvents';
import { postEmailForNewsletter } from '../../helpers/postEmailForNewsletter';

interface NewsletterAnalyticsProps {
  email: string;
  location: string;
  newsletterId: number;
}

export interface PianoNewsletterEvent {
  eventName: string;
  params: {
    email: string;
    newsletterid: number;
    trackingid: string;
    termid: string;
  };
}

interface BrandLandingNewsletterEventProps {
  event: PianoNewsletterEvent;
  location: string;
}

const fireNewsletterAnalytics = ({
  email,
  location,
}: NewsletterAnalyticsProps) => {
  const { type, id } = newsletterDataMap[location];
  advancedBlueconicEvent({
    eventName: 'Brand Page Ad',
    context: ['newsletter'],
  });
  analytics.track(
    'NEWSLETTER_PREFERENCES',
    {
      status: 'optin',
      type,
      newsletter_id: id,
      email,
      location,
    },
    { transport: 'sendBeacon' },
  );
};

export const brandLandingNewsletterEvent = ({
  event,
  location,
}: BrandLandingNewsletterEventProps) => {
  const {
    eventName,
    params: { email, newsletterid, trackingid, termid },
  } = event;

  const pianoSuccessCb = () => {
    postPianoConversion({
      trackingId: trackingid,
      termId: termid,
      termName: 'Newsletter Signup',
    });
  };

  switch (eventName) {
    case 'brand-landing-newsletter':
      fireNewsletterAnalytics({
        email,
        location,
        newsletterId: newsletterid,
      });
      postEmailForNewsletter({
        email,
        newsletterid,
        type: location,
        successCallback: pianoSuccessCb,
      });
      break;
  }
};
