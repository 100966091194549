import styles from './fullWidthContentBlock.module.scss';

interface FullWidthContentBlockProps {
  header: string;
  content: string;
  className?: string;
}

const FullWidthContentBlock: React.FC<FullWidthContentBlockProps> = ({
  header,
  content,
  className,
}) => {
  return (
    <div data-testid="full-width-content-block" className={styles.wrapper}>
      <h2
        className={`${styles.header} ${className}`}
        dangerouslySetInnerHTML={{ __html: header }}
      />
      <p
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default FullWidthContentBlock;
