export type BlueconicEvent = {
  eventName: string;
  context: string[];
};

export const advancedBlueconicEvent = ({
  eventName,
  context,
}: BlueconicEvent) => {
  window?.blueConicClient?.event.publish(eventName, context);
};
