import analytics from 'lib/analytics';
import { advancedBlueconicEvent } from 'lib/utils/blueconicEvents';

interface BrandsLandingAdsPresentedArgs {
  location: string;
}

interface NewsletterDataValues {
  type: string;
  id: number;
}

export const newsletterDataMap: Record<string, NewsletterDataValues> = {
  brand_page_ako: {
    type: 'Notes From the Test Kitchen',
    id: 3,
  },
  brand_page_cco: {
    type: "Cook's Country Watch and Cook",
    id: 10,
  },
  brand_page_cio: {
    type: "Cook's Insider",
    id: 7,
  },
};

export const brandLandingAdsPresentedEvent = ({
  location,
}: BrandsLandingAdsPresentedArgs) => {
  const eventListenerCallback = (evt: { data: string }) => {
    if (typeof evt.data.indexOf !== 'undefined') {
      if (evt.data.indexOf('BrandLandingFreeTrial|') === 0) {
        const offers = evt.data.split('|')[1];
        advancedBlueconicEvent({
          eventName: 'Brand Page Ad Seen',
          context: [`${offers}`],
        });
        analytics.track(
          'BRAND_PAGE_AD',
          {
            location,
            status: 'Presented',
            ad_type: 'single_product',
            offers_presented: offers,
          },
          { transport: 'sendBeacon' },
        );
      } else if (evt.data.indexOf('BrandLandingNewsletter|') === 0) {
        const { type, id } = newsletterDataMap[location];
        advancedBlueconicEvent({
          eventName: 'Brand Page Ad Seen',
          context: ['newsletter'],
        });
        analytics.track(
          'NEWSLETTER_SIGNUP_PRESENTED',
          {
            location,
            type,
            newsletter_id: id,
          },
          { transport: 'sendBeacon' },
        );
      }
    }
  };

  window.addEventListener('message', eventListenerCallback);
  return () => {
    window.removeEventListener('message', eventListenerCallback);
  };
};
