import styles from './bylineImage.module.scss';

export interface BylineImageProps {
  className?: string;
  imageUrl: string;
  imageAlt?: string;
  initials: string;
}

export const BylineImage: React.FC<BylineImageProps> = ({
  imageUrl,
  imageAlt,
  initials,
}) => {
  return (
    <>
      {imageUrl ? (
        <img className={styles['author-image']} alt={imageAlt} src={imageUrl} />
      ) : (
        <div className={styles['author-initial-container']}>
          <p className={styles['author-initials']}>{initials}</p>
        </div>
      )}
    </>
  );
};

export default BylineImage;
