import styles from './StandardDocCardCarousel.module.scss';
import StandardDocCard, {
  StandardDocCardProps,
} from '../StandardDocCard/StandardDocCard';
import ScrollCarousel from '../ScrollCarousel/ScrollCarousel';

interface StandardDocCardCarouselProps {
  carouselCards: StandardDocCardProps[];
  carouselTitle: string;
}

const StandardDocCardCarousel: React.FC<StandardDocCardCarouselProps> = ({
  carouselCards,
  carouselTitle,
}) => (
  <div
    data-testid={`standard-card-carousel-${carouselTitle}`}
    className={styles.standardDocCardCarouselWrapper}
  >
    <ScrollCarousel title={carouselTitle}>
      {carouselCards.map((el) => {
        const { cloudinaryId, headline, link } = el;
        return (
          <StandardDocCard
            key={`standard-card-carousel-${link}`}
            cloudinaryId={cloudinaryId}
            headline={headline}
            link={link}
          />
        );
      })}
    </ScrollCarousel>
  </div>
);

export default StandardDocCardCarousel;
