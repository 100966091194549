import classNames from 'classnames/bind';

import styles from './byline.module.scss';
import { BylineImage } from './BylineImage';
import { getImageUrl } from 'lib/cloudinary';

const cx = classNames.bind(styles);
export interface Author {
  id: number;
  firstName: string;
  lastName?: string;
  image: { altText: string; url: string };
}

export interface BylineProps {
  className?: string;
  authors: Author[];
  isPrepended?: boolean;
}

export const Byline: React.FC<BylineProps> = ({
  className,
  authors,
  isPrepended = false,
}) => {
  const classNames = cx({
    authors: true,
    [`authors--has-${authors.length === 2 ? 'two' : 'many'}`]:
      authors.length >= 2,
  });
  return (
    <div className={`${classNames} ${className}`}>
      <ul className={styles['author-image-list']}>
        {authors.map((author, i) => {
          const path = `/authors/${author.id}-${author.firstName}-${author.lastName}`;
          const initials = `${author.firstName.substring(
            0,
            1,
          )}${author?.lastName?.substring(0, 1)}`;
          if (i <= 2) {
            return (
              <li
                key={`${path}-${i}-image`}
                className={styles['author-image-list__item']}
              >
                <BylineImage
                  imageUrl={
                    author.image?.url
                      ? getImageUrl(author.image?.url, 'thumbnail')
                      : ''
                  }
                  imageAlt={author.image?.altText}
                  initials={initials.toUpperCase()}
                />
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
      <span className={styles['author-names']}>
        {isPrepended && <span>By </span>}
        {authors.map((author, i, arr) => {
          const name =
            arr.length > 1
              ? author.firstName
              : `${author.firstName} ${author?.lastName}`;

          const conjunction = () => {
            if (arr.length === 1 || i === arr.length - 1) {
              return;
            } else if (arr.length <= 3 && i === arr.length - 2) {
              return ' and ';
            } else if (arr.length > 3 && i === 2) {
              return ' and others';
            } else {
              return ', ';
            }
          };

          if (i <= 2) {
            return (
              <>
                <>{name}</>
                {conjunction()}
              </>
            );
          } else {
            return null;
          }
        })}
      </span>
    </div>
  );
};

export default Byline;
