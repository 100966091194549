import styles from './BrandImageGrid.module.scss';
import { getImageUrl, baseImageConfig } from '../../../lib/cloudinary';

type Image = {
  altText: string;
  cloudinaryId: string;
};

interface ImageProps {
  image1: Image;
  image2: Image;
  image3: Image;
  image4: Image;
}

interface ImageConfig {
  crop: string;
  fetch_format: string;
  height: number;
  quality: string;
  secure: boolean;
  width: number;
}

interface DeviceImageConfig {
  desktop: ImageConfig;
  mobile: ImageConfig;
  tablet: ImageConfig;
}

const BrandImageGrid: React.FC<ImageProps> = ({
  image1,
  image2,
  image3,
  image4,
}) => {
  const imgConfigs = {
    img1: {
      desktop: { ...baseImageConfig, width: 378, height: 623 },
      mobile: { ...baseImageConfig, width: 378, height: 514 },
      tablet: { ...baseImageConfig, width: 378, height: 623 },
    },
    img2: {
      desktop: { ...baseImageConfig, width: 741, height: 297 },
      mobile: { ...baseImageConfig, width: 378, height: 152 },
      tablet: { ...baseImageConfig, width: 741, height: 297 },
    },
    img3: {
      desktop: { ...baseImageConfig, width: 375, height: 310 },
      mobile: { ...baseImageConfig, width: 178, height: 159 },
      tablet: { ...baseImageConfig, width: 375, height: 310 },
    },
    img4: {
      desktop: { ...baseImageConfig, width: 375, height: 310 },
      mobile: { ...baseImageConfig, width: 178, height: 159 },
      tablet: { ...baseImageConfig, width: 375, height: 310 },
    },
  };

  const renderImage = (image: Image, config: DeviceImageConfig) => (
    <picture>
      <source
        srcSet={getImageUrl(image.cloudinaryId, config.desktop)}
        media="(min-width: 1024px)"
      />
      <source
        srcSet={getImageUrl(image.cloudinaryId, config.tablet)}
        media="(min-width:768px)"
      />
      <source
        srcSet={getImageUrl(image.cloudinaryId, config.mobile)}
        media="(min-width: 200px)"
      />
      <img
        src={getImageUrl(image.cloudinaryId, config.desktop)}
        alt={image.altText}
        loading="lazy"
      />
    </picture>
  );

  return (
    <div data-testid="four-image-grid" className={styles.imageGridWrapper}>
      <div className={styles.image1}>
        {renderImage(image1, imgConfigs.img1)}
      </div>
      <div className={styles.image2}>
        {renderImage(image2, imgConfigs.img2)}
      </div>
      <div className={styles.image3}>
        {renderImage(image3, imgConfigs.img3)}
      </div>
      <div className={styles.image4}>
        {renderImage(image4, imgConfigs.img4)}
      </div>
    </div>
  );
};

export default BrandImageGrid;
