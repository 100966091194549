import styles from './BrandHero.module.scss';
import { getImageUrl, baseImageConfig } from './../../../lib/cloudinary';

interface CloudinaryIds {
  desktop: string;
  tablet: string;
  mobile: string;
}
interface ImageProps {
  altText: string;
  link?: string;
  cloudinaryIds: CloudinaryIds;
}

const BrandHero: React.FC<ImageProps> = ({ altText, link, cloudinaryIds }) => {
  const { desktop, tablet, mobile } = cloudinaryIds;
  const imgCfg = {
    mobile: {
      ...baseImageConfig,
      width: 375,
    },
    tablet: {
      ...baseImageConfig,
      width: 768,
    },
    desktop: {
      ...baseImageConfig,
      width: 1380,
    },
  };

  const renderImage = () => (
    <picture>
      <source
        srcSet={getImageUrl(desktop, imgCfg.desktop)}
        media="(min-width: 1024px)"
      />
      <source
        srcSet={getImageUrl(tablet, imgCfg.tablet)}
        media="(min-width:768px)"
      />
      <source
        srcSet={getImageUrl(mobile, imgCfg.mobile)}
        media="(min-width: 200px)"
      />
      <img src={getImageUrl(desktop, imgCfg.desktop)} alt={altText} />
    </picture>
  );

  return (
    <div data-testid="brand-hero" className={styles.brandHeroWrapper}>
      {link ? <a href={link}>{renderImage()}</a> : renderImage()}
    </div>
  );
};

export default BrandHero;
