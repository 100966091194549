import atk from 'lib/api/atk';
import honeybadger from 'lib/errors/honeybadger';
import { PianoNewsletterEvent } from '../eventHandlers/BrandsLanding/EmailNewsletter';
import utils from 'lib/utils';
import { UserSegment } from 'lib/hooks/useUserState';
import parseUserClient from '../../../../components/integrations/Mixpanel/parseUserClient';

interface PostEmailForNewsletterProps
  extends Pick<PianoNewsletterEvent['params'], 'email' | 'newsletterid'> {
  type?: string;
  documentId?: string;
  documentTitle?: string;
  docType?: string;
  successCallback: () => void;
}

export const postEmailForNewsletter = async ({
  email,
  newsletterid,
  type,
  documentId,
  documentTitle,
  docType,
  successCallback,
}: PostEmailForNewsletterProps) => {
  const subdomain = utils.getSubdomain();
  const url = `https://${subdomain}.americastestkitchen.com/newsletter/signup`;
  const user = parseUserClient();
  const segment = user?.segment as UserSegment;
  const emailCaptureType = type || '';
  const currentUrl = document.location.href || '';
  try {
    const response = await atk.post(url, {
      email,
      newsletter_id: newsletterid,
      mixpanel_attributes: {
        email_capture_type: emailCaptureType,
        current_url: currentUrl,
      },
      sfmc_params: {
        doc_id: documentId || '',
        doc_title: documentTitle || '',
        doc_type: docType || '',
        doc_url: currentUrl,
        email_capture_type: emailCaptureType,
        user_segment: segment,
      },
    });
    if (response && response.message === 'success') {
      successCallback();
    }
  } catch (error: any) {
    honeybadger.notify(error);
  }
};
